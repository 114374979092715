article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	body {
		-webkit-text-size-adjust: none;
	}

/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	body {
    font-family: 'Roboto', Georgia, Times;
    font-size: 0.8rem;
    line-height: 1.4em;
	}

		body.is-loading *, body.is-loading *:before, body.is-loading *:after, body.is-switching *, body.is-switching *:before, body.is-switching *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
			-moz-transition-delay: none !important;
			-webkit-transition-delay: none !important;
			-ms-transition-delay: none !important;
			transition-delay: none !important;
		}

/* Type */

	html {
		font-size: 16pt;
	}


		@media screen and (max-width: 736px) {

			html {
				font-size: 11pt;
			}

		}

		@media screen and (max-width: 360px) {

			html {
				font-size: 9pt;
			}

		}

	body, input, select, textarea {
		font-family: 'Roboto', Georgia, Times;
		font-size: 0.8rem;
	}

	a {
		-moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		border-bottom: dotted 1px rgba(255, 255, 255, 0.5);
		text-decoration: none;
    color: #3f51b5;
	}

		a:hover {
			border-bottom-color: transparent;
      color: #3f51b5;
		}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 0.5rem 0;
		font-size: 0.8rem;
	}

	h1, h2, h3, h4, h5, h6 {
		line-height: 1.5;
		margin: 0 0 0.1rem 0;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
		}

	h1 {
		font-size: 2.25rem;
		line-height: 1.3;
    border-bottom: 8px solid #3f51b5;
    text-transform: none;
	}

	h2 {
    text-align: left;
		font-size: 1.5rem;
		line-height: 1.4;
    margin-bottom: 1rem;
    text-transform: none;
    border-bottom: 4px solid #3f51b5;
    width: fit-content;
	}

	h3 {
		font-size: 1rem;
	}

	h4 {
    text-align: left;
		font-size: 0.8rem;
	}

	h5 {
		font-size: 0.7rem;
	}

	h6 {
		font-size: 0.6rem;
	}

	@media screen and (max-width: 736px) {

		h1 {
			font-size: 1.75rem;
			line-height: 1.4;
      line-height: 70px;
      border-bottom: 0px;
		}

		h2 {
			font-size: 1.25em;
			line-height: 1.5;
		}

	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9rem;
		margin: 0 0 2rem 0;
	}

		pre code {
			display: block;
			line-height: 1.75;
			padding: 1rem 1.5rem;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 1px #000000;
		margin: 2.75rem 0;
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2rem 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 0.5rem 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;
		}

			ul.actions li {
				display: inline-block;
				padding: 0 1rem 0 0;
				vertical-align: middle;
			}

				ul.actions li:last-child {
					padding-right: 0;
				}

			ul.actions.small li {
				padding: 0 0.5rem 0 0;
			}

			ul.actions.vertical li {
				display: block;
				padding: 1rem 0 0 0;
			}

				ul.actions.vertical li:first-child {
					padding-top: 0;
				}

				ul.actions.vertical li > * {
					margin-bottom: 0;
				}

			ul.actions.vertical.small li {
				padding: 0.5rem 0 0 0;
			}

				ul.actions.vertical.small li:first-child {
					padding-top: 0;
				}

			ul.actions.fit {
				display: table;
				margin-left: -1rem;
				padding: 0;
				table-layout: fixed;
				width: calc(100% + 1rem);
			}

				ul.actions.fit li {
					display: table-cell;
					padding: 0 0 0 1rem;
				}

					ul.actions.fit li > * {
						margin-bottom: 0;
					}

				ul.actions.fit.small {
					margin-left: -0.5rem;
					width: calc(100% + 0.5rem);
				}

					ul.actions.fit.small li {
						padding: 0 0 0 0.5rem;
					}

			@media screen and (max-width: 480px) {

				ul.actions {
					margin: 0 0 2rem 0;
				}

					ul.actions li {
						padding: 1rem 0 0 0;
						display: block;
						text-align: center;
						width: 100%;
					}

						ul.actions li:first-child {
							padding-top: 0;
						}

						ul.actions li > * {
							width: 100%;
							margin: 0 !important;
						}

							ul.actions li > *.icon:before {
								margin-left: -2em;
							}

					ul.actions.small li {
						padding: 0.5rem 0 0 0;
					}

						ul.actions.small li:first-child {
							padding-top: 0;
						}

			}

	dl {
		margin: 0 0 2rem 0;
	}

		dl dt {
			display: block;
			margin: 0 0 1rem 0;
		}

		dl dd {
			margin-left: 2rem;
		}

/* BG */

	#bg {
		-moz-transform: scale(1.0);
		-webkit-transform: scale(1.0);
		-ms-transform: scale(1.0);
		transform: scale(1.0);
		-webkit-backface-visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
    background-color: white;
	}


/* Wrapper */

  .wrap-pad {
		padding: 1rem 2rem;
    @media screen and (max-width: 1680px) {
			#wrapper {
				padding: 1rem 2rem;
			}

		}

		@media screen and (max-width: 736px) {

			#wrapper {
				padding: 1rem 1rem;
			}

		}

		@media screen and (max-width: 480px) {

			#wrapper {
				padding: 1rem;
			}

		}
  }

	#wrapper {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;

    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

		-moz-justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-justify-content: space-between;
		justify-content: space-between;
		position: relative;
		min-height: 100vh;
		width: 100%;
		z-index: 3;
	}

		

/* Header */

	#header {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;

		max-width: 100%;
		text-align: center;
	}

		#header > :first-child {
			margin-top: 0;
      margin-bottom: 1em;
		}

			#header > :first-child:before {
				display: none;
			}

		#header .content {
			max-width: 100%;
		}

    #header .content ul li span {
      font-size: 1.2em;
    }

			#header .content .inner {
				max-height: 40rem;
				overflow: hidden;
        width: fit-content;
			}

				#header .content .inner > :last-child {
					margin-bottom: 0;
				}


		@media screen and (max-width: 980px) {

			#header .content p br {
				display: none;
			}

		}

		@media screen and (max-width: 736px) {

			#header > * {
				margin-top: 0rem;
			}

				#header > *:before {
					top: calc(-2rem - 1px);
					height: calc(2rem + 1px);
				}

			#header .logo {
				width: 4.75rem;
				height: 4.75rem;
				line-height: 4.75rem;
			}

				#header .logo .icon:before {
					font-size: 1.75rem;
				}

			#header .content .inner {
				padding: 0.5rem 1rem;
			}

			#header .content p {
				line-height: 1.875;
			}

		}

		@media screen and (max-width: 480px) {

			#header {
				padding: 0rem 0;
			}

				#header .content .inner {
					padding: 2.5rem 1.0;
				}

		}

/* Main */

	#main {
		-moz-flex-grow: 1;
		-webkit-flex-grow: 1;
		-ms-flex-grow: 1;
		flex-grow: 1;
		-moz-flex-shrink: 1;
		-webkit-flex-shrink: 1;
		-ms-flex-shrink: 1;
		flex-shrink: 1;
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-align-items: center;
		-webkit-align-items: center;
		-ms-align-items: center;
		align-items: center;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		-moz-flex-direction: column;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		position: relative;
		max-width: 100%;
		z-index: 3;
	}

		#main article {
			-moz-transform: translateY(0.25rem);
			-webkit-transform: translateY(0.25rem);
			-ms-transform: translateY(0.25rem);
			transform: translateY(0.25rem);
			padding: 4.5rem 2.5rem 1.5rem 2.5rem ;
			position: relative;
			width: 40rem;
			max-width: 100%;
			background-color: rgba(27, 31, 34, 0.85);
			border-radius: 4px;
			opacity: 0;
		}

			#main article.active {
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				opacity: 1;
			}

			#main article .close {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 4rem;
				height: 4rem;
				cursor: pointer;
				text-indent: 4rem;
				overflow: hidden;
				white-space: nowrap;
			}

				#main article .close:before {
					content: '';
					display: block;
					position: absolute;
					top: 0.75rem;
					left: 0.75rem;
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 100%;
					background-position: center;
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px' viewBox='0 0 20 20' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23ffffff%3B stroke-width: 1%3B %7D%3C/style%3E%3Cline x1='2' y1='2' x2='18' y2='18' /%3E%3Cline x1='18' y1='2' x2='2' y2='18' /%3E%3C/svg%3E");
					background-size: 20px 20px;
					background-repeat: no-repeat;
				}

				#main article .close:hover:before {
					background-color: rgba(255, 255, 255, 0.075);
				}

				#main article .close:active:before {
					background-color: rgba(255, 255, 255, 0.175);
				}

		@media screen and (max-width: 736px) {

			#main article {
				padding: 3.5rem 2rem 0.5rem 2rem ;
			}

				#main article .close:before {
					top: 0.875rem;
					left: 0.875rem;
					width: 2.25rem;
					height: 2.25rem;
					background-size: 14px 14px;
				}

		}

		@media screen and (max-width: 480px) {

			#main article {
				padding: 3rem 1.5rem 0.5rem 1.5rem ;
			}

		}

/* Footer */

	#footer {
		-moz-transition: -moz-transform 0.325s ease-in-out, -moz-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
		-webkit-transition: -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
		-ms-transition: -ms-transform 0.325s ease-in-out, -ms-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
		transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
		width: 100%;
		max-width: 100%;
		margin-top: 0rem;
		text-align: center;
	}

		#footer .copyright {
			letter-spacing: 0.2rem;
			font-size: 0.6rem;
			opacity: 0.75;
			margin-bottom: 0;
		}

		body.is-article-visible #footer {
			-moz-transform: scale(0.95);
			-webkit-transform: scale(0.95);
			-ms-transform: scale(0.95);
			transform: scale(0.95);
			-moz-filter: blur(0.1rem);
			-webkit-filter: blur(0.1rem);
			-ms-filter: blur(0.1rem);
			filter: blur(0.1rem);
			opacity: 0;
		}

		body.is-loading #footer {
			opacity: 0;
		}



ul .date {
  margin-top: 0px;
  font-size: 0.8em;
  font-style: italic;
}

.date-spn {
  display: block;
  font-size: 0.7em;
}

input[type='checkbox'] {
  display: none;
}

.lbl-toggle {
  display: block;

  padding: 1rem;
	color: #000000;
	line-height: 1.5;
	margin: 0 0 1rem 0;

	text-align: left;
	font-size: 1.2rem;
	line-height: 1.4;
	letter-spacing: 0.2rem;

  cursor: pointer;

  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: #7C5A0B;
}


.lbl-toggle::before {
  content: ' ';
  display: inline-block;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);

  transition: transform .2s ease-out;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;

  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: fit-content;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.side-btns {
  position: absolute;
  right: 50px;
  top: 1.8em;
}

.side-btns a {
  margin-left: 10px;
  font-size: 1em;
}

.paper-content {
  font-size: 1.0em;
  margin-bottom: 40px; 
}

.paper-content b {
  font-size: 1.0em;
}

.paper-pub-info b {

}

.paper-image {
  height: 180px !important;
  border-radius: 5px;
  max-width: 100%;
}

.paper-image-container {
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .paper-image-container {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .paper-content {
    margin-bottom: 0px !important;
  }
}

.pub-container {
  margin-bottom: 1.5em;
  width: 100% !important;
  max-width: 100% !important;

  .row {
      margin-bottom: 30px;
  }
}


.projects-view  {
  font-size: 0.9em;
}
.projects-view p {
  font-size: 0.9em;
}
#me-picture {
  height:70px;
  margin-left: 20px;
  border-radius: 35px;
}

.projects-view {
  .row {
    margin-bottom: 1em;
  }
}

.big-link {
  font-weight: bold;
}

.title-link {
  color: black;
}

.title-link:hover {
  text-decoration: none;
}

code {
  font-size: 0.7rem !important;
}

article img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

figcaption p {
  font-style: italic;
  font-weight: normal !important;
}

